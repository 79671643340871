import AuthBase from "../base/AuthBase";
import {Button, Col, Divider, Form, Input, Row, Typography} from "antd";
import {commonFormProps, formSubmitButtonCommonProps} from "../../constants/forms";
import {useState} from "react";
import {postAPI} from "../../utils/apiRequest";
import {USER_LOGIN} from "../../constants/api";
import {loginReduxAction} from "../../redux/actions/authActions";
import {useAppDispatch} from "../../lib/hooks";
import {Link} from "react-router-dom";

const {Title} = Typography;
export default function Login() {
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    const loginMethod = (values: object) => {
        setLoading(true);
        postAPI(USER_LOGIN, values).then(res => {
            if(res.access) {
                // @ts-ignore
                dispatch(loginReduxAction(res))
            }
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
        })
    }
    return <AuthBase>
        <Title level={2} className={"text-center"}>Login</Title>
        <Form onFinish={loginMethod} disabled={loading} layout={"vertical"}>
            <Form.Item label={"Email"} name={"email"} required={true}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Password"} name={"password"} required={true}>
                <Input.Password/>
            </Form.Item>
            <Form.Item >
                <Button type="primary" htmlType="submit" loading={loading} block size={"large"}>
                    Login
                </Button>
            </Form.Item>
        </Form>
        <Row justify={"space-around"}>
            <Col>
                {process.env.REACT_APP_SIGN_UP_ENABLED ? <Link to={"/sign-up"}>New Here?</Link> : null}
                <Divider type={"vertical"}/>
                <Link to={"/forgot-password"}>Forgot Password</Link>
            </Col>
        </Row>
    </AuthBase>
}