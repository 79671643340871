import AuthBase from "../base/AuthBase";
import {Button, Col, Form, Input, Row, Typography} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {useAppDispatch} from "../../lib/hooks";
import {postAPI} from "../../utils/apiRequest";
import {RESET_PASSWORD_MAIL, SEND_USER_RESET_PASSWORD_MAIL} from "../../constants/api";
import {displayMessage} from "../../utils/common";
import {SUCCESS_MSG_TYPE} from "../../constants/dataKeys";
const {Title} = Typography
export default function ResetPassword(){
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const signupMethod = (values:{email:string}) =>{
        setLoading(true);
        postAPI(RESET_PASSWORD_MAIL, {code: params.code, ...values}).then(res => {
            displayMessage(SUCCESS_MSG_TYPE,`Password Updated Successfully! `)
            navigate('/login')
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
            console.log(err)
        })
    }
    return <AuthBase>
        <Title level={2} className={"mt-0"}>Reset Password</Title>
        <Form  onFinish={signupMethod} disabled={loading} layout={"vertical"}>
            <Form.Item label={"Password"} name={"password"} required={true}>
                <Input.Password/>
            </Form.Item>
            <Form.Item >
                <Button type="primary" htmlType="submit" loading={loading} block size={"large"}>
                    Reset Password
                </Button>
            </Form.Item>
        </Form>
        <Row justify={"space-around"}>
            <Col>
                <Link to={"/"}>Go back to login</Link>
            </Col>
        </Row>
    </AuthBase>
}