import React from 'react';
import {Avatar, Layout, Menu, Select, theme} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DashboardOutlined, FileImageOutlined, HomeOutlined, SendOutlined, SettingOutlined} from '@ant-design/icons';
import * as _ from "lodash"
import {useDispatch, useSelector} from "react-redux";
import {globalSiderCollapsed} from "../../../redux/actions/globalReduxActions";

const {Sider} = Layout;
const {SubMenu} = Menu;
const {useToken} = theme;
export default function AppSider() {
    const dispatch = useDispatch()
    const { token } = useToken();
    const {siderCollapsed} = useSelector(state => _.get(state,'global',{siderCollapsed: false}))
    const setCollapse = (value : boolean) =>{
        // @ts-ignore
        dispatch(globalSiderCollapsed(value))
    }
    return (
        <Sider
            theme={'light'}
            collapsed={siderCollapsed}
            onCollapse={setCollapse}
            collapsible
            width={225}
        >
            <RoleMenuSelector/>
        </Sider>
    );

}


function RoleMenuSelector(props: any) {
    const {siderCollapsed} = useSelector(state => _.get(state,'global',{siderCollapsed: false}))
    const darkTheme = useSelector(state => _.get(state, 'global.darkTheme', false))
    const siteList = useSelector(state => _.get(state, 'site.list', []))
    const is_client_gam = useSelector(state => _.get(state, 'site.selected.is_client_gam'))
    const params = useParams();
    const navigate = useNavigate()
    return (
        <Menu
            mode='inline'
            // defaultSelectedKeys={[props.location.pathname]}
            key={`${_.get(props.route, 'cat')}-${_.get(props.route, 'subCat')}`}
            defaultOpenKeys={[
                `${_.get(props.route, 'cat')}-${_.get(props.route, 'subCat')}`,
            ]}
            style={{
                border: 0,
                height: 'calc(100vh - 80px)',
                overflowX: 'hidden',
                overflowY: 'scroll',
            }}
        >
            <Link to={'/'}>
                <img
                    src={darkTheme ? process.env["REACT_APP_LOGO_DARK"] : process.env["REACT_APP_LOGO"]}
                    alt={`${process.env["REACT_APP_NAME"]} Logo`}
                    style={{maxWidth: '100%', maxHeight: 72, margin: 'auto', display:'block'}}
                />
            </Link>
            <Select style={{width: '100%', marginTop: 8, marginBottom: 8}}
                    className={"site-select-dropdown"}
                    size={"large"}
                    bordered={false}
                    value={_.get(params, 'siteId', '')}
                    onChange={(value) => navigate(`/${value}`)}>
                {siteList.map((site: any) => <Select.Option key={site.id} value={`${site.id}`}>
                    <div className={"site-dropdown-select-options"}>
                        <Avatar src={`//${site.site_url}/favicon.ico`} shape="square">{site.site_name[0]}</Avatar>
                        {siderCollapsed ? null : <div>
                            {site.site_name}
                            <br/>
                            <small>{site.site_url}</small>
                        </div>}
                    </div>
                </Select.Option>)}
            </Select>
            <Menu.Divider/>
            <Menu.Item key={`/`} icon={<HomeOutlined/>}>
                <Link to={`/`}>All Sites</Link>
            </Menu.Item>
            <Menu.Item key={`/${_.get(params, 'siteId', '')}`} icon={<DashboardOutlined/>}>
                <Link to={`/${_.get(params, 'siteId', '')}`}>Dashboard</Link>
            </Menu.Item>
            <Menu.Divider/>
            {/*<Menu.ItemGroup title={"Ad Manager"}>*/}
            {is_client_gam ? <Menu.Item key={`/${_.get(params, 'siteId', '')}/gam/automate`} icon={<SendOutlined/>}>
                <Link to={`/${_.get(params, 'siteId', '')}/gam/automate`}>Quick Automate</Link>
            </Menu.Item> : null}
            {/*<Menu.Item key={`/${_.get(params, 'siteId', '')}/gam/order`} icon={<FileImageOutlined/>}>*/}
            {/*    <Link to={`/${_.get(params, 'siteId', '')}/gam/orders`}>Orders</Link>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key={`/${_.get(params, 'siteId', '')}/gam/lineitem`} icon={<FileImageOutlined/>}>*/}
            {/*    <Link to={`/${_.get(params, 'siteId', '')}/gam/lineitem`}>Line Items</Link>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key={`/${_.get(params, 'siteId', '')}/gam/creatives`} icon={<FileImageOutlined/>}>*/}
            {/*    <Link to={`/${_.get(params, 'siteId', '')}/gam/creatives`}>Creatives</Link>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key={'/gam/keyvalue'} icon={<FileImageOutlined/>}>*/}
            {/*    <Link to={'/gam/keyvalue'}>Key Values</Link>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key={`/${_.get(params, 'siteId', '')}/gam/adunits`} icon={<FileImageOutlined/>}>*/}
            {/*    <Link to={`/${_.get(params, 'siteId', '')}/gam/adunits`}>Ad Units</Link>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key={'/gam/association'} icon={<FileImageOutlined/>}>*/}
            {/*    <Link to={'/gam/association'}>Line Item Association</Link>*/}
            {/*</Menu.Item>*/}

            {/*</Menu.ItemGroup>*/}
            {process.env["REACT_APP_SHOW_REPORTS"] ?
                // <Menu.ItemGroup title={"Reports"}>
                <Menu.Item key={`/${_.get(params, 'siteId', '')}/report/revenue`} icon={<FileImageOutlined/>}>
                    <Link to={`/${_.get(params, 'siteId', '')}/report/revenue`}>Revenue</Link>
                </Menu.Item>
                // </Menu.ItemGroup>
             : null}
            <Menu.Divider/>
            <Menu.Item key={`/${_.get(params, 'siteId', '')}/settings`} icon={<SettingOutlined/>}>
                <Link to={`/${_.get(params, 'siteId', '')}/settings`}>Admin</Link>
            </Menu.Item>

        </Menu>
    );

    return null;
}
