import AppBase from "../../../../base/AppBase";
import {Card, Tabs, Typography} from "antd";
import React, {useState} from "react";
import SiteSettingsGam from "./gam";
import SiteSettingsUsers from "./users";
import {useSelector} from "react-redux";
import * as _ from "lodash";
import PermissionDenied from "../../../../common/errors/PermissionDenied";

const {Title} = Typography
export default function SiteSettings() {
    const [activeTabKey,setActiveTabKey] = useState('gam')
    const is_client_gam = useSelector(state => _.get(state, 'site.selected.is_client_gam'))
    const tabItems = [{
        label: "Users",
        key: "users",
        tab: "Users"
    }, {
        label: "GAM",
        key: "gam",
        tab: "GAM"
    }]
    const tabContent:Record<string, React.ReactNode> = {
        'gam' : is_client_gam ? <SiteSettingsGam/> : <PermissionDenied/>,
        'users' : <SiteSettingsUsers/>
    }
    return <AppBase>
        <Title level={2}>Site Settings</Title>
        <Card tabList={tabItems} onTabChange={setActiveTabKey} activeTabKey={activeTabKey}>
            {tabContent[activeTabKey]}
        </Card>
    </AppBase>
}