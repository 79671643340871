import React, {useEffect, useState} from "react";
import {Button, Card, Col, Divider, Form, Input, message, Row, Steps, Typography, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {commonFormProps, formSubmitButtonCommonProps} from "../../../../../../constants/forms";
import {displayMessage, makeURL} from "../../../../../../utils/common";
import {FILE_UPLOAD, GAM_CREDENTIAL_SUBMIT, MY_SITES_LIST} from "../../../../../../constants/api";
import {getAPI, getAuthToken, postAPI, putAPI} from "../../../../../../utils/apiRequest";
import * as lockr from "lockr";
import {AUTH_TOKEN, SUCCESS_MSG_TYPE} from "../../../../../../constants/dataKeys";
import {storeSiteList} from "../../../../../../redux/actions/siteActions";
import {
    getCredentialsFilePathFromFileUploadFormValue,
    getFileListFromFileUploadProps
} from "../../../../../../utils/fileUploadUtils";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

const {Title} = Typography;
export default function SiteSettingsGam() {
    const [editDetails, setEditDetails] = useState(true);
    const [current, setCurrent] = useState(0);

    const onChange = (value: number) => {
        setCurrent(value);
    };
    const next = () => {
        setCurrent(current + 1);
    };
    return <>
        <Row gutter={[16, 16]}>
            <Col md={24}>
                <Row gutter={[16, 16]} justify={"space-around"}>
                    <Col md={18} xs={24}>
                        <Steps
                            className={'gam-upload-credentials-steps'}
                            current={current}
                            onChange={onChange}
                            items={[
                                {
                                    title: 'Step 1',
                                    description: 'Turn on API access'
                                },
                                {
                                    title: 'Step 2',
                                    description: 'Add Service Account User'
                                }
                            ]}
                        />
                    </Col>
                    <Col md={24} xs={24}>
                        <Divider/>
                        {current === 0 ? <Step1Content/> : null}
                        {current === 1 ? <Step2Content/> : null}
                        {current < 1 && (
                            <Button type="primary" onClick={() => next()} style={{display: 'block', float: 'right'}}>
                                Next
                            </Button>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>

    </>
}

function Step1Content() {
    return <ol>
        <li><a href="https://admanager.google.com/" target={"_blank"}>Sign in</a> to Google Ad Manager.</li>


        <li> Navigate to Admin -&gt; Global settings -&gt; Network settings.</li>

        <li> Toggle <strong>API access</strong> to turn on access.</li>

        <li> Accept the "API Terms and Conditions" by clicking <strong>Confirm</strong>.</li>

        <li> Scroll to the bottom of the page and click <strong>Save</strong>.</li>
    </ol>
}

function Step2Content() {
    return <Row align={'middle'}>
        <Col md={12}>
            <ol>
                <li> Open the <a href="https://console.cloud.google.com/apis/credentials" target={"_blank"}>Google API
                    Console Credentials </a>page.
                </li>

                <li> From the project drop-down, choose</li>
                <ol>

                    <li><strong>Create a new project</strong>,</li>

                    <li> Enter a name for the project,</li>

                    <li> edit the provided Project ID(optional)</li>
                    <li>
                        Click <strong>Create</strong>.
                    </li>
                </ol>
                <li> Go to newly created Project,</li>
                <ol>

                    <li> Click <strong>CREATE SERVICE ACCOUNT</strong>.</li>
                    <li> Enter details and click Done.</li>

                </ol>
                <li> Go to <strong>KEYS</strong> Tab</li>
                <li> Click <strong>ADD KEY</strong></li>
                <li> Select <strong>CREATE NEW KEY</strong></li>
                <li> Select Key Type<strong>JSON</strong></li>
                <li> Download JSON file</li>
                <li> Open JSON file and copy <strong>"client_email"</strong> value</li>

                <ol>

                    <li> Go to Google Ad Manager -&gt; Global Settings -&gt; Add Service Account</li>
                    <li> Enter <strong>client_email</strong> from previous</li>

                </ol>


                <li> Click <strong>Create</strong> to download a file containing a private key.</li>

                <li> Upload the JSON file and click <strong>Submit</strong></li>
            </ol>
        </Col>
        <Col md={12}>
            <GamCredentialsUploadForm/>
        </Col>
    </Row>
}

function Step3Content() {
    return <Row align={'middle'}>
        <Col md={12}>
            <ol>

                <li> Upload the JSON file and click <strong>Submit</strong></li>
            </ol>
        </Col>
        <Col md={12}>
            <GamCredentialsUploadForm/>
        </Col>
    </Row>
}

function GamCredentialsUploadForm() {
    const params = useParams()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const singleUploadprops = {
        maxCount: 1,
        name: 'client_cred_file',
        data: {},
        action: makeURL(GAM_CREDENTIAL_SUBMIT),
        headers: {
            Authorization: `Bearer ${lockr.get(AUTH_TOKEN)}`
        },
        onChange(info: any) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const saveGamCredentials = (values: any) => {
        const reqData = {
            ...values,
            client_cred_path: getCredentialsFilePathFromFileUploadFormValue(values.client_cred_path)
        }
        putAPI(MY_SITES_LIST, {id: params.siteId, ...reqData}).then(() => {
            getSitesList()
            displayMessage(SUCCESS_MSG_TYPE, "GAM Credentials Uploaded Successfully!")
        })
    }

    const getSitesList = () => {
        getAPI(MY_SITES_LIST).then(res => {
            // @ts-ignore
            dispatch(storeSiteList(res.results))
            navigate(`/${params.siteId}/`)
        });
    }

    return <Form {...commonFormProps} onFinish={saveGamCredentials}>
        <Form.Item label="Credential File" valuePropName="fileList" getValueFromEvent={normFile}
                   name={'client_cred_path'} required={true}>
            <Upload {...singleUploadprops}>
                <Button icon={<PlusOutlined/>}>
                    Upload
                </Button>
            </Upload>
        </Form.Item>
        <Form.Item label={"Network Code"} name={"client_gam_id"} required={true}>
            <Input/>
        </Form.Item>
        <Form.Item {...formSubmitButtonCommonProps}>
            <Button type="primary" htmlType="submit" loading={loading}>
                Submit
            </Button>
        </Form.Item>
    </Form>
}