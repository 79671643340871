import {Button, Form, Input} from "antd";
import {commonFormProps, formSubmitButtonCommonProps} from "../../../../constants/forms";
import {useState} from "react";
import {getAPI, postAPI, putAPI} from "../../../../utils/apiRequest";
import {SITE_LIST} from "../../../../redux/reduxConstant";
import {MY_SITES_LIST, SITE_DETAIL_API} from "../../../../constants/api";
import {storeSiteList} from "../../../../redux/actions/siteActions";
import {useAppDispatch} from "../../../../lib/hooks";
import {interpolate} from "../../../../utils/common";
import * as _ from "lodash";
export default function CreateSiteForm({siteData, setSiteAddOrEditForm}:{siteData:any, setSiteAddOrEditForm:any}){
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const createSite = (values:any) =>{
        if(siteData.id)
            putAPI(MY_SITES_LIST, {id: siteData.id, ...values}).then(res=>{
                getSitesList();
                setSiteAddOrEditForm()
            })
        else
            postAPI(MY_SITES_LIST,values).then(res=>{
                getSitesList()
                setSiteAddOrEditForm()
            })
    }
    const getSitesList = () => {
        getAPI(MY_SITES_LIST).then(res => {
            // @ts-ignore
            dispatch(storeSiteList(res.results))
        });
    }
    return <Form {...commonFormProps} onFinish={createSite} initialValues={siteData} >
        <Form.Item label={"Site Name"} name={"site_name"}>
            <Input/>
        </Form.Item>
        <Form.Item label={"Site Url"} name={"site_url"} >
            <Input disabled={siteData.id}/>
        </Form.Item>
        <Form.Item {...formSubmitButtonCommonProps}>
            <Button type="primary" htmlType="submit" loading={loading}>
                Submit
            </Button>
        </Form.Item>
    </Form>
}