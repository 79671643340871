import React from 'react';
import {Button, Result, Row, Col} from 'antd';
import {BuildOutlined} from "@ant-design/icons"
export default function UnderMainTenance() {
    return (
        <Row style={{marginTop: '20px'}}>
            <Col span={18} offset={4}>
                <Result
                    title={
                        <span>
                <h1>
                  <b>Under Maintenance</b>
                </h1>
              </span>
                    }
                    subTitle="We are adding very powerful features to our application. Please check back soon."
                    icon={<BuildOutlined />}
                />
            </Col>
        </Row>
    );
}

