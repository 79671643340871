import {Card, Col, Layout, Row} from "antd";
import {useEffect} from "react";
import * as _ from "lodash";
import {useNavigate, useParams} from 'react-router-dom';
import {useAppSelector} from "../../lib/hooks";
import {useSelector} from "react-redux";

const {Content} = Layout;
export default function AuthBase(props: any) {
    const darkTheme  = useSelector(state => _.get(state, 'global.darkTheme', false))
    const {isLoggedIn} = useAppSelector(state => _.get(state, 'auth', {isLoggedIn: false}))
    const navigate = useNavigate();
    const params = useParams()
    useEffect(() => {
        if (isLoggedIn && !params.code) {
            navigate("/");
        }
    }, [isLoggedIn, params.code]);
    return <Layout>
        <Content
            className={"main-container"}
            style={{
                height: `calc(100vh)`,
                overflow: 'auto',
            }}
        >
            <Row style={{
                minHeight: `calc(100vh)`,
                overflow: 'auto',
            }} justify={"center"} align={"middle"}>
                <Col md={6} sm={10} xs={24}>
                    <Card>
                        <img
                            src={darkTheme ? process.env["REACT_APP_LOGO_DARK"] :process.env["REACT_APP_LOGO"]}
                            alt={`${process.env["REACT_APP_NAME"]} Logo`}
                            style={{
                                maxWidth: '100%',
                                maxHeight: 82,
                                display: 'block',
                                margin: "auto"
                            }}
                        />
                        {props.children}
                    </Card>
                </Col>
                <Col md={18} sm={14} xs={0}>
                    <img src="/assets/world-map-bg.svg" alt={`${process.env["REACT_APP_NAME"]} Auth Background`}
                         style={{maxWidth: '100%', maxHeight: '100vh', margin: 'auto',display: 'block'}}/>
                </Col>
            </Row>
        </Content>
    </Layout>

}
