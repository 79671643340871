export const REQUIRED_FIELD_MESSAGE = "'${label}' is required!";
export const REQUIRED_FIELD_WITH_NAME_MESSAGE = "'${label}' is required!";
export const PASSWORD_FIELD_VALIDATION_MESSAGE = "Password must contain at least 12 characters, 4 alphabets, 1 uppercase character, 1 lowercase character, 1 number and 1 special character"
export const ERROR_INTERNET_CONNECTIVITY = 'Connection Failed';
export const ERROR_MESSAGE_500 = 'Internal Server Error';
export const ERROR_MESSAGE_404 = 'Not Found';
export const ERROR_MESSAGE_400 = 'Bad Request';
export const ERROR_MESSAGE_401 = 'Unauthorized Request';
export const ERROR_MESSAGE_403 = 'Access Denied';

