import {BrowserRouter as Router} from 'react-router-dom';
import RoutesHome from './RoutesHome';
import {ConfigProvider, Layout, theme} from "antd";


import {GoogleOAuthProvider} from '@react-oauth/google';
import {useSelector} from "react-redux";
import * as _ from "lodash";


function App() {
    const darkTheme = useSelector(state => _.get(state, 'global.darkTheme', false))
    return (
        <GoogleOAuthProvider clientId="199645339316-pd73qoe59g9bn6si9hsv6bg1mkfntius.apps.googleusercontent.com">
            <ConfigProvider theme={{
                algorithm: darkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
                // token: {
                //     colorPrimary: process.env.REACT_APP_PRIMARY_COLOR,
                //     colorPrimaryText: process.env.REACT_APP_PRIMARY_COLOR,
                //     colorLink : process.env.REACT_APP_PRIMARY_COLOR
                // },
            }}>
                <Layout>
                    <Router>
                        <RoutesHome/>
                    </Router>
                </Layout>
            </ConfigProvider>
        </GoogleOAuthProvider>
    );
}

export default App;