import {SELECTED_SITE, SITE_ADVERTISER_DICT, SITE_LIST, SITE_TRAFFICKER_DICT,} from '../reduxConstant';
import get from 'lodash/get';

export default (state = {list:[],selected:null}, action:{type:string,payload:any}) => {
    let selectedSiteData = null;
    switch (action.type) {
        case SITE_LIST:
            for(let i=0; i< action.payload.length; i++){
                if(get(state.list[i],'id') == get(state,'selected.id')){
                    selectedSiteData = action.payload[i];
                    break;
                }
            }
            return {
                ...state,
                list: action.payload,
                selected: selectedSiteData,
            };
        case SELECTED_SITE:
            for(let i=0; i< state.list.length; i++){
                if(get(state.list[i],'id') == action.payload){
                    selectedSiteData = state.list[i];
                    break;
                }
            }
            return {
                ...state,
                selected: selectedSiteData,
            };
        case SITE_ADVERTISER_DICT:
            return {
                ...state,
                selected : {
                    ...state.selected || {},
                    advertisers: action.payload.advertisers,
                    connectionStatus : action.payload.connectionStatus
                }
            };
        case SITE_TRAFFICKER_DICT:
            return {
                ...state,
                selected : {
                    ...state.selected || {},
                    traffickers: action.payload,
                }
            };
        default:
            return {
                ...state
            };
    }
};
