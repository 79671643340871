import AuthBase from "../base/AuthBase";
import {Button, Col, Form, Input, Row, Typography} from "antd";
import {commonFormProps, formSubmitButtonCommonProps} from "../../constants/forms";
import {useState} from "react";
import {postAPI} from "../../utils/apiRequest";
import {USER_SIGNUP} from "../../constants/api";
import {loginReduxAction} from "../../redux/actions/authActions";
import {useAppDispatch} from "../../lib/hooks";
import {Link} from "react-router-dom";

const {Title} = Typography;
export default function Register() {
    const [loading,setLoading] = useState(false)
    const dispatch = useAppDispatch()
    const signupMethod = (values:object) =>{
        setLoading(true);
        postAPI(USER_SIGNUP,values).then(res => {
            if(res.access) {
                // @ts-ignore
                dispatch(loginReduxAction(res))
            }
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
            console.log(err)
        })
    }
    return <AuthBase>
        <Title level={2} className={"mt-0"}>Register</Title>
        <Form  onFinish={signupMethod} disabled={loading} layout={"vertical"}>
            <Form.Item label={"First Name"} name={"first_name"} required={true}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Last Name"} name={"last_name"} required={true}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Email"} name={"email"} required={true}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Password"} name={"password"} required={true}>
                <Input.Password/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} block size={"large"}>
                    Register
                </Button>
            </Form.Item>
        </Form>
        <Row justify={"space-around"}>
            <Col>
                <Link to={"/"}>Already have a account?</Link>
            </Col>
        </Row>
    </AuthBase>
}