import AppBase from "../../base/AppBase";
import {useSelector} from "react-redux";
import {Button, Card, Col, Modal, Result, Row, Typography} from "antd";
import * as _ from "lodash";
import CreateSiteForm from "./manage/CreateSiteForm";
import SiteCard from "../../common/SiteCard";
import {Link} from "react-router-dom";
import {useState} from "react";

const {Title} = Typography;
export default function SiteHome() {
    const siteList = useSelector(state => _.get(state, 'site.list', []))
    const [siteAddOrEditForm, setSiteAddOrEditForm] = useState<null | any>(null);
    return <AppBase noSider={true}>
        <Title level={2}>My Sites <Button style={{display: 'block', float: 'right'}} type={"primary"}
                                          onClick={() => setSiteAddOrEditForm({id: null})}>New Site</Button></Title>
        <Row gutter={[16, 16]}>
            {siteList.length ? siteList.map((site: any) => <Col md={6}>
                <Link to={`/${site.id}`}>
                    <SiteCard siteData={site} setSiteAddOrEditForm={setSiteAddOrEditForm}/>
                </Link>
            </Col>) : <Col md={24}>
                <Card>
                    <Result
                        icon={<img src="/assets/add-site.png" style={{width: 200}}/>}
                        title="Welcome, Let's start with creating your first Site!"
                        extra={<Button type="primary" onClick={() => setSiteAddOrEditForm({id: null})}>Create
                            Site</Button>}
                    />
                </Card>
            </Col>}
        </Row>
        <Modal open={siteAddOrEditForm} footer={null}
               centered
               title={_.get(siteAddOrEditForm, 'id') ? 'Edit Site' : 'Create Site'} destroyOnClose={true}
               onCancel={() => setSiteAddOrEditForm(null)}>
            <CreateSiteForm siteData={siteAddOrEditForm} setSiteAddOrEditForm={setSiteAddOrEditForm}/>
        </Modal>
    </AppBase>
}
