import {Avatar, Button, Card, Modal, Tag} from "antd";
import {CheckCircleOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons"
import {getAPI, postAPI, putAPI} from "../../utils/apiRequest";
import {displayMessage, interpolate} from "../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../constants/dataKeys";
import {MY_SITES_LIST, SITE_DETAIL_API} from "../../constants/api";
import {storeSiteList} from "../../redux/actions/siteActions";
import {useAppDispatch} from "../../lib/hooks";

export default function SiteCard({siteData, setSiteAddOrEditForm}: { siteData: any, setSiteAddOrEditForm:any }) {
    const dispatch = useAppDispatch()
    const deleteSite = () =>{
        if(!siteData.is_client_gam) {
            displayMessage(ERROR_MSG_TYPE, "Deleting this site is not allowed.")
            return false;
        }
        Modal.confirm({
            title: `Are you sure to delete ${siteData.site_name}?`,
            content: 'Once deleted, all the information and data will be lost.',
            onOk:()=>{
                putAPI(interpolate(MY_SITES_LIST,[siteData.id]),{is_active:false,id: siteData.id}).then(res => {
                    getSitesList()
                    displayMessage(SUCCESS_MSG_TYPE,"Site Deleted Successfully!");
                })
            },
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onCancel:(close)=>{
                close()
            }
        });

    }

    const getSitesList = () => {
        getAPI(MY_SITES_LIST).then(res => {
            // @ts-ignore
            dispatch(storeSiteList(res.results))
        });
    }

    return <Card size="small"
                 actions={[<Button size="small" type={"link"} icon={<EditOutlined/>} onClick={(e)=>{e.preventDefault();setSiteAddOrEditForm(siteData)}}/>,
                     <Button size="small" type={"link"} danger icon={<DeleteOutlined/>} onClick={(e)=>{e.preventDefault();deleteSite()}}/>]}>
        <Card.Meta
            style={{minHeight:70}}
            avatar={<Avatar src={`//${siteData.site_url}/favicon.ico`} shape="square">{siteData.site_name[0]}</Avatar>}
            title={siteData.site_name}
            description={siteData.site_url}
        />

    </Card>
}