import AppBase from "../../../../../base/AppBase";
import {Table, Typography} from "antd";
import {getAPI} from "../../../../../../utils/apiRequest";
import {GAM_LINE_ITEM_LIST} from "../../../../../../constants/api";
import {useEffect, useState} from "react";
import * as _ from "lodash";
import {useParams} from "react-router-dom";

const {Title} = Typography;
export default function GamLineItems() {
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [lineItemsList, setLineItemsList] = useState({})
    const [paginationData, setPaginationData] = useState({
        pageSize: 20,
        current: 1
    })
    const loadLineItems = () => {
        setLoading(true)
        getAPI(GAM_LINE_ITEM_LIST, {
            page_size: paginationData.pageSize,
            page: paginationData.current,
            site: params.siteId
        }).then(result => {
            setLineItemsList(result)
            setLoading(false)
        })
    }
    useEffect(loadLineItems, [paginationData])
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Type',
        dataIndex: "adType",
        key: "adType",
        render: (value:any, record:object)=>_.get(record,"adSenseSettings.adType","--")
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    }]
    return <AppBase>
        <Title level={2}>LineItems</Title>
        <Table size="small"
               loading={loading}
               dataSource={_.get(lineItemsList, 'results')}
               columns={columns}
               pagination={{
                   total: _.get(lineItemsList, 'totalResultSetSize'),
                   ...paginationData
               }}
        onChange={(pagination, filters, sorter, extra)=>setPaginationData(prevState => { return {...prevState, ...pagination}})}/>
    </AppBase>
}