import AppBase from "../../../../base/AppBase";
import {Alert, Avatar, Card, Col, Divider, Row, Select, Statistic, Table, Tag, theme, Typography} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {GAMHomeContent} from "../gam";
import AutomationDashboardWidget from "./AutomationDashboardWidget";
import {useSelector} from "react-redux";
import * as _ from "lodash";
import {Link, useNavigate, useParams} from "react-router-dom";
import GamConnectionDashboardWidget from "./GamConnectionDashboardWidget";
import React, {useEffect, useState} from "react";
import {getAPI} from "../../../../../utils/apiRequest";
import {GAM_ADUNIT_WISE_REPORT, GAM_DAY_WISE_REPORT} from "../../../../../constants/api";
import dayjs from "dayjs";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

const {useToken} = theme;
const {Title} = Typography;
export default function Dashboard() {
    const [data, setData] = useState<any>({data: [], extra: []});
    const [adUnitData, setAdUnitData] = useState<any>([]);
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({page_size: 10, page: 1});
    const [filters, setFilters] = useState({
        date__gte: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
        date__lte: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    })
    const params = useParams();
    const {token} = useToken();
    const is_client_gam = useSelector(state => _.get(state, 'site.selected.is_client_gam'))
    useEffect(() => {
        if (process.env["REACT_APP_SHOW_REPORTS"]) {
            getDayWiseReport();
            getAdUnitWiseReport()
        }
    }, [is_client_gam, filters, params.siteId])
    const getDayWiseReport = () => {
        setLoading(true)
        getAPI(GAM_DAY_WISE_REPORT, {pagination: false, site_id: params.siteId, ...filters}).then(res => {
            setData({
                data: res?.map((item: any) => {
                    return {
                        ...item,
                        revenue: parseFloat((item.revenue).toFixed(2)),
                        total_ecpm: parseFloat((item.total_ecpm).toFixed(2))
                    }
                }),
            })
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }
    const getAdUnitWiseReport = () => {
        setLoading(true)
        getAPI(GAM_ADUNIT_WISE_REPORT, {pagination: false, site_id: params.siteId, ...filters}).then(res => {
            setAdUnitData(res?.extra.map((item: any) => {
                return {
                    ...item,
                    revenue: parseFloat((item.ecpm * item.all_imp / 1000).toFixed(2))
                }
            }))
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }
    const columns = [{
        title: 'Date',
        dataIndex: 'date',
        key: 'date'
    }, {
        title: 'Total eCPM',
        dataIndex: 'total_ecpm',
        key: 'total_ecpm',
        render: (value: number) => `$ ${value}`
    }, {
        title: 'Total Imp.',
        dataIndex: 'total_ad_ex_imp',
        key: 'total_ad_ex_imp',
    }, {
        title: 'Revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        align: undefined,
        render: (value: number) => `$ ${value}`
    }]
    return <AppBase>
        <Title level={2}>Dashboard
            <Select style={{float: 'right', width: 200}}
                    defaultValue={JSON.stringify({
                        date__gte: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
                        date__lte: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
                    })}
                    onChange={(value: string) => setFilters(prevState => {
                        return {
                            ...prevState,
                            ...JSON.parse(value)
                        }
                    })}>
                <Select.Option value={JSON.stringify({
                    date__gte: dayjs().startOf( 'day').format('YYYY-MM-DD'),
                    date__lte: dayjs().endOf('day').format('YYYY-MM-DD')
                })}>Today</Select.Option>
                <Select.Option value={JSON.stringify({
                    date__gte: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
                    date__lte: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
                })}>Yesterday</Select.Option>
                <Select.Option value={JSON.stringify({
                    date__gte: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
                    date__lte: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
                })}>Last 7 Days</Select.Option>
                <Select.Option value={JSON.stringify({
                    date__gte: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
                    date__lte: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
                })}>Last 30 Days</Select.Option>
                <Select.Option value={JSON.stringify({
                    date__gte: dayjs().startOf('month').format('YYYY-MM-DD'),
                    date__lte: dayjs().subtract(1, 'days').format('YYYY-MM-DD')
                })}>This Month</Select.Option>
                <Select.Option value={JSON.stringify({
                    date__gte: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    date__lte: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                })}>Last Month</Select.Option>
            </Select>
        </Title>
        <Row gutter={[16, 16]}>
            {is_client_gam ? <Col md={6} xs={12}>
                <Link to={`/${params.siteId}/settings`}>
                    <GamConnectionDashboardWidget/>
                </Link>
            </Col> : null}
            {process.env["REACT_APP_SHOW_REPORTS"] ? <>
                <Col md={6} xs={12}>
                    <Card size={"small"}>
                        <Statistic title="Total Impressions" value={adUnitData.reduce((a: number, b: any) => a + b.all_imp, 0)}/>
                    </Card>
                </Col>
                {/*<Col md={6} xs={12}>*/}
                {/*    <Card size={"small"}>*/}
                {/*        <Statistic title="eCPM" value={row.ecpm} precision={2} prefix={'$'}/>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col md={6} xs={12}>*/}
                {/*    <Card size={"small"}>*/}
                {/*        <Statistic title="Active View" value={row.active_view} precision={2} suffix={"%"}/>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                <Col md={6} xs={12}>
                    <Card size={"small"}>
                        <Statistic title="Revenue" value={adUnitData.reduce((a: number, b: any) => a + b.revenue, 0)}
                                   precision={2} prefix={"$"}/>
                    </Card>
                </Col>
            </>:null}
            {process.env["REACT_APP_SHOW_REPORTS"] ? <>
                <Col md={18} xs={24}>
                    <Card bodyStyle={{height: 400}}>
                        <Title level={4} className={"mt-0"}>Revenue Trend</Title>
                        <ResponsiveContainer width="100%" height="95%">
                            <LineChart data={data.data} margin={{right: 50, top: 20}}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="date"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" dataKey="revenue" stroke={token.colorPrimary} width={25}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
                <Col md={6} xs={24}>
                    <Card bodyStyle={{height: 400}}>
                        <Title level={4} className={"mt-0"}>Ad Unit Revenue</Title>
                        <ResponsiveContainer width="100%" height="95%">
                            <PieChart margin={{top: 20}}>
                                <Pie data={adUnitData} dataKey="revenue" nameKey="ad_unit_name" cx="50%" cy="50%"
                                     fill={token.colorPrimary}/>
                                <Tooltip/>
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
                <Col md={18} xs={24}>
                    <Card bodyStyle={{padding: 0}}>
                        <Table columns={columns}
                               size={"small"}
                               dataSource={data.data}
                               pagination={false}/>
                    </Card>
                </Col>
            </> : null}
            {/*<Col md={6} xs={12}>*/}
            {/*    <Card hoverable>*/}
            {/*        <Card.Meta*/}
            {/*            avatar={<Avatar src="/assets/icons/gtm_logo.png" shape="square"/>}*/}
            {/*            title="Google Tag Manager"*/}
            {/*            description={<Tag color="default">*/}
            {/*                Coming Soon*/}
            {/*            </Tag>}*/}
            {/*        />*/}
            {/*    </Card>*/}
            {/*</Col>*/}
            {/*<Col md={6} xs={12}>*/}
            {/*    <Card hoverable>*/}
            {/*        <Card.Meta*/}
            {/*            avatar={<Avatar src="/assets/icons/ga_logo.png" shape="square"/>}*/}
            {/*            title="Google Analytics"*/}
            {/*            description={<Tag color="default">*/}
            {/*                Coming Soon*/}
            {/*            </Tag>}*/}
            {/*        />*/}
            {/*    </Card>*/}
            {/*</Col>*/}
        </Row>
        {/*<Row gutter={16}>*/}
        {/*    <Col md={24} xs={24}>*/}
        {/*        <Divider orientation={"left"}>Google Ad Manager</Divider>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        {/*<GAMHomeContent/>*/}
        {is_client_gam ? <>
            <Divider orientation={"left"}>Recent Automation</Divider>
            <Row>
                <Col md={24} xs={24}>
                    <AutomationDashboardWidget/>
                </Col>
            </Row>
        </> : null}
    </AppBase>
}