import { makeFileURL } from './common';
import {postAPI} from "./apiRequest";

export const getFileListFromFileUploadProps = function(props: {file: object, fileList: [object?]}) {
    if (!props) {
        return [];
    }
    if (props.file) {
        return props.fileList || [];
    }
    if (Array.isArray(props)) {
        return props;
    }
};

export const convertFileUrlToUploadFileList = function(props: []) {
    if (!props || (Array.isArray(props) && !props.length)) {
        return [];
    } else if (Array.isArray(props)) {
        return props.map((item,index) => {
            return {
                uid: index + 1,
                name: 'Uploaded File',
                status: 'done',
                url: makeFileURL(item),
                image: makeFileURL(item),
                response: {
                    url: makeFileURL(item),
                },
            };
        });
    } else {
        return [
            {
                uid: '-1',
                name: 'Uploaded File',
                status: 'done',
                url: makeFileURL(props),
                image: makeFileURL(props),
                response: {
                    url: makeFileURL(props),
                },
            },
        ];
    }
};

export const getFilePathFromFileUploadFormValue = function(props: {file? : {response? : {url?: string}}, fileList? : [{response?:{url: string}}]} = {}) {
    if (props.file && props.file.response) return props.file.response.url;
    else if (
        props.fileList &&
        props.fileList.length &&
        props.fileList[0].response
    ) {
        return props.fileList[0].response.url;
    } else if (Array.isArray(props) && props.length && props[0].response) {
        return props[0].response.url;
    }
    return null;
};

export const getFilePathArrayFromFileUploadFormValue = function(props:{file? : {response? : {url?: string}}, fileList? : [{response:{url: string}}]} = {}) {
    if (
        props.fileList &&
        props.fileList.length
    ) {
        return props.fileList.map(item=> item.response.url);
    } else if (Array.isArray(props) && props.length) {
        return props.map(item=>item.response.url);
    }
    return null;
};

export const getCredentialsFilePathFromFileUploadFormValue = function(props: {file? : {response? : {client_cred_path?: string}}, fileList? : [{response?:{client_cred_path: string}}]} = {}) {
    if (props.file && props.file.response) return props.file.response.client_cred_path;
    else if (
        props.fileList &&
        props.fileList.length &&
        props.fileList[0].response
    ) {
        return props.fileList[0].response.client_cred_path;
    } else if (Array.isArray(props) && props.length && props[0].response) {
        return props[0].response.client_cred_path;
    }
    return null;
};

export const handleBulkUploadErrors = (errors: [{detail:[string]}]) => {
    if (errors) {
        let errorList:[object?]  = [];
        errors.forEach(function(error, index) {
            if (error.detail) {
                error.detail.forEach(function(item) {
                    errorList.push({
                        position: index + 1,
                        error_msg: item,
                    });
                });
            }
        });
        return errorList;
    }
};
