import {Avatar, Card, Tag} from "antd";
import * as _ from "lodash";
import {CheckCircleOutlined, CloseCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {useAppSelector} from "../../../../../lib/hooks";

export default function GamConnectionDashboardWidget() {
    return <Card hoverable>
        <Card.Meta
            avatar={<Avatar src="/assets/icons/gam_logo.jpeg" shape="square"/>}
            title="Google Ad Manager"
            description={<TagRender/>}
        />
    </Card>
}

function TagRender() {
    const selectedSite = useSelector(state => _.get(state, 'site.selected', {}))
    const connectionStatus: boolean|undefined = useAppSelector(state => _.get(state, 'site.selected.connectionStatus'))
    if (!_.get(selectedSite, 'client_gam_id')) {
        return <Tag icon={<CloseCircleOutlined/>} color="error">
            Not Connected
        </Tag>
    }
    if(connectionStatus === undefined){
        return  <Tag icon={<SyncOutlined spin />} color="processing">Checking Connection...</Tag>
    }
    if(connectionStatus){
        return <Tag icon={
            <CheckCircleOutlined/>} color="success">
            Connected ({_.get(selectedSite, 'client_gam_id')})
        </Tag>
    }
    return <Tag icon={<CloseCircleOutlined/>} color="error">
        Not Connected
    </Tag>
}