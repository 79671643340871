import {SELECTED_SITE, SITE_ADVERTISER_DICT, SITE_LIST, SITE_TRAFFICKER_DICT} from "../reduxConstant";

export const storeSiteList = (list) => (dispatch, getState) => {
    return dispatch({
        type: SITE_LIST,
        payload: list,
    });
};

export const selectSite = (id) => (dispatch, getState) => {
    return dispatch({
        type: SELECTED_SITE,
        payload: id,
    });
};

export const storeAdvertiserDict = (dict) => (dispatch, getState) => {
    return dispatch({
        type: SITE_ADVERTISER_DICT,
        payload: dict,
    });
};

export const storeTraffickerDict = (dict) => (dispatch, getState) => {
    return dispatch({
        type: SITE_TRAFFICKER_DICT,
        payload: dict,
    });
};