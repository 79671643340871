import AppBase from "../../../../../base/AppBase";
import {Table, Typography} from "antd";
import {getAPI} from "../../../../../../utils/apiRequest";
import {GAM_CREATIVE_LIST} from "../../../../../../constants/api";
import {useEffect, useState} from "react";
import * as _ from "lodash";
import {useParams} from "react-router-dom";

const {Title} = Typography;
export default function GamCreatives() {
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [creativesList, setCreativesList] = useState({})
    const [paginationData, setPaginationData] = useState({
        pageSize: 20,
        current: 1
    })
    const loadCreatives = () => {
        setLoading(true)
        getAPI(GAM_CREATIVE_LIST, {
            page_size: paginationData.pageSize,
            page: paginationData.current,
            site: params.siteId
        }).then(result => {
            setCreativesList(result)
            setLoading(false)
        })
    }
    useEffect(loadCreatives, [paginationData])
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        render:(value:any) => `${value.width}x${value.height}`
    }]
    return <AppBase>
        <Title level={2}>Creatives</Title>
        <Table size="small"
               loading={loading}
               dataSource={_.get(creativesList, 'results')}
               columns={columns}
               pagination={{
                   total: _.get(creativesList, 'totalResultSetSize'),
                   ...paginationData
               }}
        onChange={(pagination, filters, sorter, extra)=>setPaginationData(prevState => { return {...prevState, ...pagination}})}/>
    </AppBase>
}