import React from 'react';
import {Layout, Typography} from 'antd';

const {Footer} = Layout;
const {Text} = Typography;

export default function AppFooter() {
    return (
        <Footer>
            <p style={{textAlign: 'center'}}>
                Version: <Text type='secondary'>{process.env.REACT_APP_VERSION}</Text>
            </p>
        </Footer>
    );
}

