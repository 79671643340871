import React, {ReactNode} from 'react';
import {Alert, Button, Layout, Typography} from 'antd';
import AppFooter from './footer/AppFooter';
import AppHeader from './header/AppHeader';
import AppSider from "./sider/AppSider";
import {useAppSelector} from "../../lib/hooks";
import * as _ from 'lodash';
import { GoogleLogin } from '@react-oauth/google';
import {useSelector} from "react-redux";
import {USER_MAIL_VERIFY_SEND} from "../../constants/api";
import {postAPI} from "../../utils/apiRequest";
import {displayMessage} from "../../utils/common";
import {SUCCESS_MSG_TYPE} from "../../constants/dataKeys";
const {Text} = Typography;
const {Content} = Layout;

export default function AppBase({
                                    noSider,
                                    noHeader,
                                    loggedIn,
                                    noPadding,
                                    noBreadcrumb,
                                    children,
                                    ...props
                                }: {
    noSider?: boolean | undefined,
    noHeader?: boolean | undefined,
    loggedIn?: boolean | undefined,
    noPadding?: boolean | undefined,
    noBreadcrumb?: boolean | undefined,
    children: ReactNode
}) {
    const loginUser = useSelector(state=> _.get(state,'auth.user',{}))
    const {email, is_email_verified} = useAppSelector(state=>_.get(state,'auth.user',{}))

    const sendVerificationMail = () => {
        postAPI(USER_MAIL_VERIFY_SEND,{}).then(()=>{
            displayMessage(SUCCESS_MSG_TYPE,`An Email with verification code has been sent to ${email}`)
        })
    }
    return (
        <Layout>
            {noSider ? null : <AppSider/>}

            <Layout
                className='site-layout'
            >
                {noHeader ? null : <AppHeader noBreadcrumb={noBreadcrumb} loginUser={loginUser}/>}
                <Layout>
                    {!is_email_verified ? <Alert banner type="error"
                                                 action={
                                                     <Button size="small" type="text" onClick={sendVerificationMail}>
                                                         Send Verification Mail
                                                     </Button>
                                                 }
                           message={`Your email \`${email}\` is not verified. Kindly verify to keep your account secure.`}/>: null}
                    <Content
                        style={{
                            padding: noPadding ? 0 : 10,
                            height: `calc(100vh - 170px)`,
                            overflow: 'auto',
                        }}
                    >
                        <div className={"main-container"}>
                            {/*<GoogleLogin*/}
                            {/*    onSuccess={credentialResponse => {*/}
                            {/*        console.log(credentialResponse);*/}
                            {/*    }}*/}
                            {/*    onError={() => {*/}
                            {/*        console.log('Login Failed');*/}
                            {/*    }}*/}

                            {/*/>*/}
                            {children}

                        </div>
                        <AppFooter/>
                    </Content>

                </Layout>
            </Layout>
        </Layout>
    );
}

