import { persistStore } from "redux-persist";
import { createStore, applyMiddleware, compose } from "redux";
import middlewares from "./middlewares";
import reducers from "./persistReducer";

const configureStore = () => {
  const reactEnhancers = compose(applyMiddleware(...middlewares));
  const store = createStore(reducers, reactEnhancers);
  const persistor = persistStore(store);
  return { store, persistor };
};

const configuredStore = configureStore();
export default configuredStore

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof configuredStore.store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof configuredStore.store.dispatch