import {
  GLOBAL_SIDER_COLLAPSED,
  GLOBAL_UPDATE_ROUTE,
  GLOBAL_SIDER_MENU, GLOBAL_TOGGLE_THEME,
} from '../reduxConstant';

export const globalUpdateRoute = (routeParams) => (dispatch, getState) => {
  return dispatch({
    type: GLOBAL_UPDATE_ROUTE,
    payload: { ...routeParams },
  });
};
export const globalSiderCollapsed = (option) => (dispatch, getState) => {
  return dispatch({
    type: GLOBAL_SIDER_COLLAPSED,
    payload: !!option,
  });
};
export const globalSiderMenu = (value) => (dispatch, getState) => {
  return dispatch({
    type: GLOBAL_SIDER_MENU,
    payload: value[0],
  });
};
export const globalToggleTheme = (value) => (dispatch, getState) => {
  return dispatch({
    type: GLOBAL_TOGGLE_THEME,
    payload: value,
  });
};
