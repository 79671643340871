import {displayMessage, handleErrorResponse, makeURL} from '../common';
import {AUTH_TOKEN, REFRESH_TOKEN, SUCCESS_MSG_TYPE} from '../../constants/dataKeys';
import axiosInstance from "./axiosInstance";
import axios from "axios";
import * as lockr from 'lockr';
import {REFRESH_API} from "../../constants/api";
import jwt_decode from "jwt-decode";
export const validateJWT = (token:string) => {
  var claims:any = jwt_decode(token) || {};

  const expirationTimeInSeconds = claims.exp * 1000;
  const now = new Date();
  const isValid = expirationTimeInSeconds >= now.getTime();

  return isValid;
};
export const getAuthToken = async function () {
  let token = lockr.get(AUTH_TOKEN);
  if(token) {
    const isValid = validateJWT(token);
    if (token && !isValid) {
      await axios({
        method: "post",
        url: makeURL(REFRESH_API),
        data: {refresh: lockr.get(REFRESH_TOKEN)},
      })
          .then(function (response) {
            let data = response.data;
            token = data.access
            lockr.set('refresh', data.refresh)
            lockr.set(AUTH_TOKEN, data.access)

          })
          .catch(async function (error) {
            handleErrorResponse(error);
          });
    }
  }
  return token;
};

export const putAPI = async function (
  URL: string,
  data: object,
  headerConfig = {}
) {
  let token = await getAuthToken();
  let authHeaders:any = {};
  if (token) {
    authHeaders.Authorization = `Bearer ${token}`;
  }
  return axiosInstance({
    method: 'put',
    url: URL,
    data: data,
    headers: {
      ...authHeaders,
      ...headerConfig,
    },
  })
    .then(function (response) {
      let data = response.data;
      if(data.message)
        displayMessage(SUCCESS_MSG_TYPE,data.message)
      return data
    })
    .catch(function (error) {
      handleErrorResponse(error);
      return Promise.reject(error)
    });
};
export const patchAPI = async function (
  URL: string,
  data: object,
  headerConfig = {}
) {
  let token = await getAuthToken();
  let authHeaders:any = {};
  if (token) {
    authHeaders.Authorization = `Bearer ${token}`;
  }
  return axiosInstance({
    method: 'patch',
    url: makeURL(URL),
    data: data,
    headers: {
      ...authHeaders,
      ...headerConfig,
    },
  })
    .then(function (response) {
      let data = response.data;
      if(data.message)
        displayMessage(SUCCESS_MSG_TYPE,data.message)
      return data
    })
    .catch(function (error) {
      handleErrorResponse(error);
      return Promise.reject(error)
    });
};
export const postAPI = async function (
  URL: string,
  data: object,
  headerConfig = {}
) {
  let token = await getAuthToken();
  let authHeaders:any = {};
  if (token) {
    authHeaders.Authorization = `Bearer ${token}`;
  }
  return axiosInstance({
    method: 'post',
    url: makeURL(URL),
    data: data,
    headers:{
      ...authHeaders,
      ...headerConfig
    }
  })
    .then(function (response) {
      let data = response.data;
      if(data.message)
        displayMessage(SUCCESS_MSG_TYPE,data.message)
      return data
    })
    .catch(function (error) {
      handleErrorResponse(error);
      return Promise.reject(error)
    });
};


export const getAPI = async function (URL: string, params: object = {}) {
  let token = await getAuthToken();
  let authHeaders:any = {};
  if (token) {
    authHeaders.Authorization = `Bearer ${token}`;
  }
  return axiosInstance({
    method: 'get',
    url: URL,
    params: params,
    headers:{
      ...authHeaders
    }
  })
    .then(function (response) {
      let data = response.data;
      if(data.message)
        displayMessage(SUCCESS_MSG_TYPE,data.message)
      return data
    })
    .catch(function (error) {
      handleErrorResponse(error);
      return Promise.reject(error)
    });
};

export const convertRouteSearchToObj = function (searchString : string) {
  if (searchString) {
    let returnObj: object = {};
    let searchStringAfterTrim = searchString.substr(1);
    let searchStringGroups = searchStringAfterTrim.split('&');
    searchStringGroups.forEach(function (pairString) {
      let valuePair: string[] = pairString.split('=');
      // @ts-ignore
      returnObj[valuePair[0]] = valuePair[1];
    });
    return returnObj;
  }
  return {};
};

export const postOuterAPI = function (
    URL: string,
    data: object,
    headerConfig: object = {},
    otherConfigs: object = {}
) {
  return axios({
    method: 'post',
    url: URL,
    data: data,
    headers: {
      ...headerConfig,
    },
    ...otherConfigs
  })
      .then(function (response) {
        let data = response.data;
        if(data.message)
          displayMessage(SUCCESS_MSG_TYPE,data.message)
        return data
      })
      .catch(function (error) {
        handleErrorResponse(error);
        return error
      });
};