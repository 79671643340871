import {Outlet, Route, Routes} from "react-router";
import {Layout} from "antd";
import Error404 from "./components/common/errors/Error404";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Dashboard from "./components/modules/site/detail/dashboard/Dashboard";
import GamOrders from "./components/modules/site/detail/gam/orders";
import GamCreatives from "./components/modules/site/detail/gam/creatives";
import GamAdUnits from "./components/modules/site/detail/gam/adunits";
import GamLineItems from "./components/modules/site/detail/gam/line-items";
import {ReactNode, useEffect} from "react";
import * as _ from "lodash";
import {useAppDispatch, useAppSelector} from "./lib/hooks";
import CreateAutomation from "./components/modules/site/detail/gam/automate";
import GamAutomationCreationForm from "./components/modules/site/detail/gam/automate/GamAutomationCreationForm";
import {getAPI} from "./utils/apiRequest";
import {GAM_ADVERTISERS_LIST, GAM_TRAFFICKERS_LIST, MY_SITES_LIST} from "./constants/api";
import ForgotPassword from "./components/auth/ForgotPassword";
import UserProfile from "./components/modules/user/profile";
import ChangePassword from "./components/modules/user/change-password";
import {selectSite, storeAdvertiserDict, storeSiteList, storeTraffickerDict} from "./redux/actions/siteActions";
import SiteHome from "./components/modules/site";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import SiteSettings from "./components/modules/site/detail/setting";
import RevenueReport from "./components/modules/site/detail/reports/revenue";
import ResetPassword from "./components/auth/ResetPassword";
import VerifyMail from "./components/auth/VerifyMail";
import UnderMainTenance from "./components/common/errors/UnderMainTenance";

export default function RoutesHome() {
    const dispatch = useAppDispatch()
    const {isLoggedIn} = useAppSelector(state => _.get(state, 'auth', {isLoggedIn: false}))
    const protectRoutes = (component: ReactNode) => {
        if(process.env.REACT_APP_UNDER_MAINTENANCE)
            return <UnderMainTenance/>
        if (isLoggedIn)
            return component;
        return <Login/>
    }
    const getSitesList = () => {
        getAPI(MY_SITES_LIST).then(res => {
            // @ts-ignore
            dispatch(storeSiteList(res.results))
        });
    }
    useEffect(() => {
        if (isLoggedIn) {
            getSitesList()
        }
    }, [isLoggedIn])

    return <Layout style={{minHeight: '100vh'}}>
        <Routes>
            <Route path='/login' element={<Login/>}/>
            {process.env.REACT_APP_SIGN_UP_ENABLED ? <Route path='/sign-up' element={<Register/>}/> : null}
            <Route path='/forgot-password' element={<ForgotPassword/>}/>
            <Route path='/password-reset/:code' element={<ResetPassword/>}/>
            <Route path='/verify/:code' element={<VerifyMail/>}/>
            <Route path='/'>
                <Route path={"user"}>
                    <Route path={"profile"} element={protectRoutes(<UserProfile/>)}/>
                    <Route path={"change-password"} element={protectRoutes(<ChangePassword/>)}/>
                </Route>
                <Route path=":siteId" element={protectRoutes(<SiteWrapper/>)}>
                    <Route index element={protectRoutes(<Dashboard/>)}/>
                    <Route path={'gam'}>
                        <Route path={'orders'} element={protectRoutes(<GamOrders/>)}/>
                        <Route path={'creatives'} element={protectRoutes(<GamCreatives/>)}/>
                        <Route path={'adunits'} element={protectRoutes(<GamAdUnits/>)}/>
                        <Route path={'lineitem'} element={protectRoutes(<GamLineItems/>)}/>
                        <Route path={'automate'} element={protectRoutes(<CreateAutomation/>)}/>
                        <Route path={'automate/create'} element={protectRoutes(<GamAutomationCreationForm/>)}/>
                    </Route>
                    {/*<Route path={'tags'} element={protectRoutes(<SiteTags/>)}/>*/}
                    <Route path={'report'}>
                        <Route path={'revenue'} element={protectRoutes(<RevenueReport/>)}/>
                    </Route>
                    <Route path={'settings'} element={protectRoutes(<SiteSettings/>)}/>
                </Route>
                <Route index element={protectRoutes(<SiteHome/>)}/>
            </Route>

            <Route path='*' element={<Error404/>}/>
        </Routes>
    </Layout>
}

function SiteWrapper() {
    const params = useParams();
    const dispatch = useAppDispatch();
    const siteList = useSelector(state => _.get(state, 'site.list', []))
    const selectedSiteData: any = useSelector(state => _.get(state, 'site.selected', {}))
    const getAdvertisersList = () => {
        getAPI(GAM_ADVERTISERS_LIST, {site: params.siteId}).then(res => {
            let dict = {}
            res.forEach((item: { id: string }) => {
                // @ts-ignore
                dict[item.id] = item
            })
            // @ts-ignore
            dispatch(storeAdvertiserDict({advertisers: dict, connectionStatus: !!Object.keys(dict).length}))
        }).catch(error => {
            // @ts-ignore
            dispatch(storeAdvertiserDict({advertisers: {}, connectionStatus: false}))
        });
    }
    const getTraffickerList = () => {
        getAPI(GAM_TRAFFICKERS_LIST, {site: params.siteId}).then(res => {
            let dict = {}
            res.forEach((item: { id: string }) => {
                // @ts-ignore
                dict[item.id] = item
            })
            // @ts-ignore
            dispatch(storeTraffickerDict(dict))
        });
    }
    useEffect(() => {
        if (params.siteId) {
            // @ts-ignore
            dispatch(selectSite(params.siteId))
        }
    }, [params.siteId, siteList])
    useEffect(() => {
        if (selectedSiteData?.client_gam_id) {
            getAdvertisersList();
            getTraffickerList()
        }
    }, [selectedSiteData?.client_gam_id && selectedSiteData?.id])
    return <Outlet/>
}