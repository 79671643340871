import AuthBase from "../base/AuthBase";
import {Button, Col, Form, Input, Row, Typography} from "antd";
import {commonFormProps, formSubmitButtonCommonProps} from "../../constants/forms";
import {useState} from "react";
import {postAPI} from "../../utils/apiRequest";
import {SEND_USER_RESET_PASSWORD_MAIL, USER_SIGNUP} from "../../constants/api";
import {loginReduxAction} from "../../redux/actions/authActions";
import {useAppDispatch} from "../../lib/hooks";
import {Link} from "react-router-dom";
import {displayMessage} from "../../utils/common";
import {SUCCESS_MSG_TYPE} from "../../constants/dataKeys";

const {Title} = Typography;
export default function ForgotPassword() {
    const [loading,setLoading] = useState(false)
    const dispatch = useAppDispatch()
    const signupMethod = (values:{email:string}) =>{
        setLoading(true);
        postAPI(SEND_USER_RESET_PASSWORD_MAIL,values).then(res => {
            displayMessage(SUCCESS_MSG_TYPE,`An Email with verification code has been sent to ${values?.email}`)
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
            console.log(err)
        })
    }
    return <AuthBase>
        <Title level={2} className={"mt-0"}>Forgot Password?</Title>
        <Form  onFinish={signupMethod} disabled={loading} layout={"vertical"}>
            <Form.Item label={"Email"} name={"email"} required={true}>
                <Input/>
            </Form.Item>
            <Form.Item >
                <Button type="primary" htmlType="submit" loading={loading} block size={"large"}>
                    Send Reset Link
                </Button>
            </Form.Item>
        </Form>
        <Row justify={"space-around"}>
            <Col>
                <Link to={"/"}>Go back to login</Link>
            </Col>
        </Row>
    </AuthBase>
}