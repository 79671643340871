import AuthBase from "../base/AuthBase";
import {Divider, Spin, Typography} from "antd";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {postAPI} from "../../utils/apiRequest";
import {RESET_PASSWORD_MAIL, USER_MAIL_VERIFY} from "../../constants/api";
import {displayMessage} from "../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../constants/dataKeys";
import {DANGER_COLOR_CODE} from "../../constants/hardData";

const {Title} = Typography
export default function VerifyMail() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const verifyMail = () => {
        setLoading(true);
        postAPI(USER_MAIL_VERIFY, {code: params.code}).then(res => {
            displayMessage(SUCCESS_MSG_TYPE, `Mail Verified Successfully! `)
            navigate('/')
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            navigate('/')
            displayMessage(ERROR_MSG_TYPE, `Mail Verified Failed! `)
            console.log(err)
        })
    }
    useEffect(() => {
        if (params.code)
            verifyMail()
    }, [params.code])
    return <AuthBase>
        <Title level={2} className={"mt-0"}>Verify Email</Title>
        <Spin spinning={loading} tip={"Verifying Mail..."}>
            <Divider/>
        </Spin>
    </AuthBase>
}