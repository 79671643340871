import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import globalReducer from "./globalReducer";
import siteReducer from "./siteReducer";

export default combineReducers({
  auth: AuthReducer,
  site: siteReducer,
  global: globalReducer,
});
