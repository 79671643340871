import {message} from 'antd';
import {
  ERROR_MESSAGE_400,
  ERROR_MESSAGE_401,
  ERROR_MESSAGE_403,
  ERROR_MESSAGE_404,
  ERROR_MESSAGE_500,
} from '../constants/message';
import {
  ERROR_MSG_TYPE,
  INFO_MSG_TYPE,
  SUCCESS_MSG_TYPE,
  WARNING_MSG_TYPE,
} from '../constants/dataKeys';

export const makeURL = function (URL: string) {
  const appURL = process.env.REACT_APP_BACKEND_BASE_URL
  return appURL+'/'+ URL;
};


export const makeFileURL = function (URL: string) {
  return  URL;
};

export const handleErrorResponse = function (error: any) {
  console.error(error)
  let response = error.response;
  if (response) {
    let status = response.status;
    if (status === 400) {
      if (Array.isArray(response.data)) {
        response.data.forEach(function (errObject: {detail: string, message: string}) {
          if (errObject.detail) {
            message.error(errObject.message);
          }
        });
      } else if (response.data.detail) {
        message.error(response.data.detail);
      } else {
        message.error(ERROR_MESSAGE_400);
      }
    } else if (status === 403) {
      if (response.data.detail) {
        message.error(response.data.detail);
      } else {
        message.error(ERROR_MESSAGE_403);
      }
    } else if (status === 404) {
      if (response.data.detail) {
        message.error(response.data.detail);
      } else {
        message.error(ERROR_MESSAGE_404);
      }
    } else if (status === 500) {
      if (response.data.detail) {
        message.error(response.data.detail);
      } else {
        message.error(ERROR_MESSAGE_500);
      }
    } else if (status === 401 || status === 402) {
      // window.location.reload();
      if (response.data.detail) {
        message.error(response.data.detail);
      } else {
        message.error(ERROR_MESSAGE_401);
      }
    }else {
      if(response?.data?.detail)
        message.error(response.data.detailg);
    }
  }
};

export const interpolate = function (theString: string, argumentArray: [any?]) {
  var regex = /%s/;
  var _r = function (p: string, c: string) {
    return p.replace(regex, c);
  };
  // @ts-ignore
  return argumentArray.reduce(_r, theString);
};

export const displayMessage = function (type: string, msg: string) {
  if (type === SUCCESS_MSG_TYPE) message.success(msg);
  else if (type === INFO_MSG_TYPE) message.info(msg);
  else if (type === WARNING_MSG_TYPE) message.warning(msg);
  else if (type === ERROR_MSG_TYPE) message.error(msg);
};

export const startLoadingMessage = function (msg: string) {
  return message.loading(msg, 0);
};
export const stopLoadingMessage = function (msgFn: Function, finishMsgType: string, finishMsg: string) {
  msgFn();
  if (finishMsgType) displayMessage(finishMsgType, finishMsg);
  return true;
};
export const stripHTML = function (html: string) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const parseQueryString = function (query: string) {
  const obj = {};
  if (query.length) {
    if (query[0] === '?' || query[0] === '#') {
      query = query.substring(1, query.length)
    }
    const tempArr = query.split('&');
    tempArr.forEach(function (str : string) {
      const arr = str.split('=');
      if (arr.length === 2) {
        // @ts-ignore
        obj[arr[0]] = arr[1]
      }
    });
  }
  return obj;
}

export const copyToClipboard = (text: string) => {
  let myTemporaryInputElement = document.createElement('input');
  myTemporaryInputElement.type = 'text';
  myTemporaryInputElement.value = text;
  document.body.appendChild(myTemporaryInputElement);
  myTemporaryInputElement.select();
  document.execCommand('Copy');
  document.body.removeChild(myTemporaryInputElement);
  displayMessage(SUCCESS_MSG_TYPE,"Copied!");
};
