/*** Base URLs **/
export const IMAGE_BASE_URL: string = '/media';
export const FILE_UPLOAD = 'uploads/';

/***
 * Auth APIs
 * **/
export const USER_SIGNUP = 'users/register/'
export const USER_LOGIN = 'users/login/';
export const REFRESH_API = 'token/refresh/'
export const SEND_USER_RESET_PASSWORD_MAIL = 'users/password_reset_mail/'
export const RESET_PASSWORD_MAIL = 'users/reset_password/'
export const UPDATE_USER_PASSWORD = "users/password_change/"
export const USER_LOGOUT = 'logout/';
export const USER_CLONE = 'users/me/';
export const USER_MAIL_VERIFY_SEND = 'users/verify_mail/'
export const USER_MAIL_VERIFY = 'users/verify/'

export const GET_FILE_UPLOAD_SIGNATURE = "media";
/***
 * SITE APIs
 * **/
export const MY_SITES_LIST = 'sites/sites/'
export const SITE_DETAIL_API = 'sites/%s/'
export const SITE_USERS = 'sites/site_users/'


/***
 * GAM APIs
 * **/

export const GAM_CREDENTIAL_SUBMIT = 'sites/sites_upload/'
export const GAM_ORDER_LIST = 'gam/orders/'

export const GAM_CREATIVE_LIST = 'gam/creatives/'

export const GAM_ADUNIT_LIST = 'gam/units/'

export const GAM_LINE_ITEM_LIST = 'gam/line-items/'

export const GAM_AUTOMATION_LIST = 'gam/automation/'
export const GAM_AUTOMATION_LOG_LIST = 'gam/automation/%s/logs/'

export const GAM_ADVERTISERS_LIST = 'gam/general/advertisers_list/'
export const GAM_TRAFFICKERS_LIST = 'gam/general/trafficker_list/'


export const GAM_REPORT = 'gam/reports/'
export const GAM_ADUNIT_WISE_REPORT = 'gam/reports/adunit_wise_report/'
export const GAM_DAY_WISE_REPORT = 'gam/reports/day_wise_report/'