import {get} from "lodash";
import axiosInstance from "../../utils/apiRequest/axiosInstance";
import {LOGIN, LOGOUT, PERSIST, USER_DETAILS} from "../reduxConstant";
import * as lockr from "lockr";
import {AUTH_TOKEN, REFRESH_TOKEN} from "../../constants/dataKeys";

const initialState = {
    user: {},
    token: null,
    isLoggedIn: null,
};

const AuthReducer = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case LOGIN:
            lockr.set(REFRESH_TOKEN, get(action, "payload.refresh") )
            lockr.set(AUTH_TOKEN, get(action, "payload.access") )
            return {
                ...state,
                ...get(action, "payload"),
                isLoggedIn: true,
            };

        case LOGOUT:
            axiosInstance.defaults.headers.common.authorization = null;
            return initialState;

        case USER_DETAILS:
            return {
                ...state,
                user: get(action, "payload.data.user"),
            };
        case PERSIST:
            if (get(action, "payload.auth.access")) {
                lockr.set(REFRESH_TOKEN, get(action, "payload.auth.refresh") )
                lockr.set(AUTH_TOKEN, get(action, "payload.auth.access") )
            }
            return state;
        default:
            return state;
    }
};

export default AuthReducer;
