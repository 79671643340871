import ReportsBase from "../ReportsBase";
import {Card, Col, Divider, Layout, Menu, MenuProps, Row, Typography} from "antd";
import {useState} from "react";
import AdUnitWiseRevenue from "./AdUnitWiseRevenue";
import DayWiseRevenueReport from "./DayWiseRevenueReport";

const {Title} = Typography;
export default function RevenueReport() {
    const [current, setCurrent] = useState('day');

    const onClick = (key: string) => {
        setCurrent(key);
    };
    const items = [
        {
            tab: 'Daily Report',
            key: 'day',
        },
        {
            tab: 'Ad Unit Report',
            key: 'adunit',
        }
        ]
    const contentList: Record<string, React.ReactNode> = {
        adunit: <AdUnitWiseRevenue/>,
        day: <DayWiseRevenueReport/>,
    }
    return <ReportsBase>
        <Title level={3}>Revenue Report</Title>
        <Layout>
            <Card style={{width: '100%', padding:0}}
                  bodyStyle={{padding: 0, paddingTop:16}}
                  tabList={items}
                  activeTabKey={current}
                  onTabChange={onClick}>
                {contentList[current]}
            </Card>
        </Layout>
    </ReportsBase>
}