import {Button, Card, Collapse, Result, Space, Tag} from "antd";
import {getAPI} from "../../../../../utils/apiRequest";
import {GAM_AUTOMATION_LIST} from "../../../../../constants/api";
import {useAppSelector} from "../../../../../lib/hooks";
import * as _ from "lodash";
import React, {useEffect, useState} from "react";
import ExpandedRowLogTimeline from "../gam/automate/ExpandedRowLogTimeline";
import {SmileOutlined} from '@ant-design/icons';
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

export default function AutomationDashboardWidget() {
    const selectedSite = useSelector(state => _.get(state, 'site.selected', {}))
    const advertisers: any = useAppSelector(state => _.get(state, 'site.selected.advertisers', {}))
    const traffickers: any = useAppSelector(state => _.get(state, 'site.selected.traffickers', {}))
    const [loading, setLoading] = useState(false)
    const [automationsList, setAutomationsList] = useState([]);
    const connectionStatus: boolean|undefined = useAppSelector(state => _.get(state, 'site.selected.connectionStatus'))
    const params = useParams();
    const loadAutomations = () => {
        setLoading(true)
        getAPI(GAM_AUTOMATION_LIST, {
            page_size: 1,
            site:params.siteId
        }).then(result => {
            setAutomationsList(_.get(result, 'results', []))
            setLoading(false)
        })
    }
    useEffect(loadAutomations, [])
    if(connectionStatus === undefined && _.get(selectedSite, 'client_gam_id'))
        return <Card loading/>
    if(connectionStatus)
        return automationsList.length ? <div>
                <Collapse destroyInactivePanel={true} accordion={true}
                          defaultActiveKey={'a'}
                          className={"automation-collapse"} ghost>
                    {automationsList?.map((item: any) =>
                        <Collapse.Panel key={'a'}
                                        extra={<Tag color={tagColorFetcher(item.status)}>{item.status}</Tag>}
                                        header={<Space>
                                            <Tag>Advertiser: {advertisers[item.advertiser_id]?.name} ({item.advertiser_id})</Tag>
                                            <Tag>Trafficker: {traffickers[item.trafficker_id]?.name} ({item.trafficker_id})</Tag>
                                            <Tag>Line Item Count: {item.line_item_count}</Tag>
                                            <Tag>Order Count: {item.order_count}</Tag>
                                            {/*<Tag>Creative Size: {item.creative_size}</Tag>*/}
                                        </Space>}>
                            <ExpandedRowLogTimeline automationId={item.id} key={item.id}/>
                        </Collapse.Panel>)}
                </Collapse>
            </div> : <Card size={'small'}>
            <Result
                icon={<SmileOutlined />}
                title="Welcome Onboard!"
                subTitle="Lets start with your first automation"
                extra={<Link to={`/${params.siteId}/gam/automate/create`}><Button type="primary">Start Automation</Button></Link>}
            />
        </Card>
    return <Card>
        <Result
            icon={<SmileOutlined />}
            title="Welcome Onboard!"
            subTitle="Lets start with your first automation"
            extra={<Link to={`/${params.siteId}/settings`}><Button type="primary">Connect Ad Manager</Button></Link>}
        />
    </Card>
}

function tagColorFetcher(status: string) {
    switch (status) {
        case 'FINISHED':
            return 'success'
        case 'IN_PROGRESS':
            return 'processing'
        case 'PENDING':
            return 'default'
        case 'FAILED':
            return 'error'
        default:
            return 'default'
    }
}