import React, {ReactNode} from "react";
import {useSelector} from "react-redux";
import * as _ from "lodash";
import {useAppSelector} from "../../../../../lib/hooks";
import {Layout} from "antd";
import AppBase from "../../../../base/AppBase";

export default function ReportsBase({
                                        noSider,
                                        noHeader,
                                        loggedIn,
                                        noPadding,
                                        noBreadcrumb,
                                        children,
                                        ...props
                                    }: {
    noSider?: boolean | undefined,
    noHeader?: boolean | undefined,
    loggedIn?: boolean | undefined,
    noPadding?: boolean | undefined,
    noBreadcrumb?: boolean | undefined,
    children: ReactNode
}) {
    const loginUser = useSelector(state => _.get(state, 'auth.user', {}))
    const {email, is_email_verified} = useAppSelector(state => _.get(state, 'auth.user', {}))
    return (
        <AppBase noPadding>
            <Layout
                className='site-layout'
                style={{
                    padding: 8
                }}
            >
                {/*{noSider ? null : <AppSider/>}*/}
                <Layout>
                    {children}
                </Layout>
            </Layout>
        </AppBase>
    );
}