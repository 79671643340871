export const LOGIN = "login";
export const LOGOUT = "logout";
export const PERSIST = "persist/REHYDRATE";
export const USER_DETAILS = "userDetails";

/**
 * *Global Reducers Constants
 * */
export const GLOBAL_UPDATE_ROUTE: string = 'global_update_route';
export const GLOBAL_SIDER_COLLAPSED: string = 'global_sider_collapsed';
export const GLOBAL_SIDER_MENU: string = 'global_sider_menu';
export const GLOBAL_TOGGLE_THEME: string = 'global_toggle_theme';

/**
 * *SITE Reducers Constants
 * */

export const SITE_LIST = 'site_list';
export const SELECTED_SITE = 'selected_site';
export const SITE_TRAFFICKER_DICT: string = 'site_trafficker_dict';
export const SITE_ADVERTISER_DICT: string = 'site_advertiser_dict';