import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import axiosInstance from "../../utils/apiRequest/axiosInstance";


const configureMiddlewares = () => {
  const middlewares = [
    thunk.withExtraArgument({
      api: axiosInstance,
    }),
  ];
  if (process.env.NODE_ENV === "development") {
    const logger = createLogger();
    middlewares.push(logger);
  }

  return middlewares;
};

export default configureMiddlewares();
