import {Button, Col, Form, Input, Row, Typography} from "antd";
import {useState} from "react";


import {Link} from "react-router-dom";
import UserBase from "../../../base-user";
import {postAPI} from "../../../../utils/apiRequest";
import {UPDATE_USER_PASSWORD, USER_SIGNUP} from "../../../../constants/api";
import {displayMessage} from "../../../../utils/common";
import {SUCCESS_MSG_TYPE} from "../../../../constants/dataKeys";

const {Title} = Typography;
export default function ChangePassword() {
    const [loading,setLoading] = useState(false)
    const [form] = Form.useForm();
    const signupMethod = (values:object) =>{
        setLoading(true);
        postAPI(UPDATE_USER_PASSWORD,values).then(res => {
            displayMessage(SUCCESS_MSG_TYPE,"Password Updates Successfully!")
            setLoading(false)
            form.resetFields()
        }).catch(err=>{
            setLoading(false)
            console.log(err)
        })
    }

    return <UserBase>
        <Row>
            <Col md={12}>
                <Title level={4} className={"mt-0"}>Change Password</Title>
                <Form form={form} onFinish={signupMethod} disabled={loading} layout={"vertical"}>
                    <Form.Item label={"Old Password"} name={"old_password"} required={true}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item label={"New Password"} name={"new_password"}  rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                               hasFeedback>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item label={"Confirm New Password"} name={"confirm_new_password"} required={true} dependencies={['new_password']}
                               hasFeedback
                               rules={[
                                   {
                                       required: true,
                                       message: 'Please confirm your password!',
                                   },
                                   ({ getFieldValue }) => ({
                                       validator(_, value) {
                                           if (!value || getFieldValue('new_password') === value) {
                                               return Promise.resolve();
                                           }
                                           return Promise.reject(new Error('The new password that you entered do not match!'));
                                       },
                                   }),
                               ]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}  size={"large"}>
                            Change Password
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    </UserBase>
}