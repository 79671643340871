import AppBase from "../../../../../base/AppBase";
import {Table, Typography} from "antd";
import {getAPI} from "../../../../../../utils/apiRequest";
import {GAM_ORDER_LIST} from "../../../../../../constants/api";
import {useEffect, useState} from "react";
import * as _ from "lodash";
import {useParams} from "react-router-dom";

const {Title} = Typography;
export default function GamOrders() {
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [ordersList, setOrdersList] = useState({})
    const [paginationData, setPaginationData] = useState({
        pageSize: 20,
        current: 1
    })
    const loadOrders = () => {
        setLoading(true)
        getAPI(GAM_ORDER_LIST, {
            page_size: paginationData.pageSize,
            page: paginationData.current,
            site: params.siteId
        }).then(result => {
            setOrdersList(result)
            setLoading(false)
        })
    }
    useEffect(loadOrders, [paginationData])
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Clicks Delivered',
        dataIndex: 'totalClicksDelivered',
        key: 'totalClicksDelivered',
    }, {
        title: 'Impressions Delivered',
        dataIndex: 'totalImpressionsDelivered',
        key: 'totalImpressionsDelivered',
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    }]
    return <AppBase>
        <Title level={2}>Orders</Title>
        <Table size="small"
               loading={loading}
               dataSource={_.get(ordersList, 'results')}
               columns={columns}
               pagination={{
                   total: _.get(ordersList, 'totalResultSetSize'),
                   ...paginationData
               }}
        onChange={(pagination, filters, sorter, extra)=>setPaginationData(prevState => { return {...prevState, ...pagination}})}/>
    </AppBase>
}