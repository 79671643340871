import AppBase from "../../../../../base/AppBase";
import {Button, Card, Form, Input, InputNumber, Select, Typography} from "antd";
import {commonFormProps, formSubmitButtonCommonProps} from "../../../../../../constants/forms";
import {useEffect, useState} from "react";
import {getAPI, postAPI, putAPI} from "../../../../../../utils/apiRequest";
import {GAM_ADVERTISERS_LIST, GAM_AUTOMATION_LIST, GAM_TRAFFICKERS_LIST} from "../../../../../../constants/api";
import {useNavigate, useParams} from "react-router-dom";

const {Title} = Typography;
export default function GamAutomationCreationForm() {
    const params = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [advertisersList, setAdvertisersList] = useState([])
    const [traffickersList, setTraffickersList] = useState([])
    const submitForm = (values: any) => {
        // setLoading(true)
        let reqData = {
            ...values,
            site: params.siteId
        }

        postAPI(GAM_AUTOMATION_LIST, reqData).then(res => {
            navigate(`/${params.siteId}/gam/automate`)
        })
    }

    const getAdvertisersList = () => {
        getAPI(GAM_ADVERTISERS_LIST,{site:params.siteId}).then(res => {
            setAdvertisersList(res)
        });
    }
    const getTraffickersList = () => {
        getAPI(GAM_TRAFFICKERS_LIST,{site:params.siteId}).then(res => {
            setTraffickersList(res)
        });
    }
    useEffect(() => {
        getAdvertisersList();
        getTraffickersList();
    },[])
    return <AppBase>
        <Title level={2}>
            Quick Automation
        </Title>
        <Card>
            <Form {...commonFormProps} onFinish={submitForm} disabled={loading} initialValues={{
                granularity:'dense',
                line_item_count: 450,
                order_count: 1,
                prefix:"ADZ"
            }}>
                <Form.Item label={"Advertiser ID"} name={"advertiser_id"} rules={[{required:true}]}>
                    <Select>
                        {advertisersList.map((advertiser: any) => <Select.Option key={advertiser.id}
                                                                                 value={advertiser.id}>{advertiser.name} ({advertiser.id})</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={"Trafficker ID"} name={"trafficker_id"} rules={[{required:true}]}>
                    <Select>
                        {traffickersList.map((trafficker: any) => <Select.Option key={trafficker.id}
                                                                                 value={trafficker.id}>{trafficker.name} ({trafficker.id})</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={"Granularity"} name={"granularity"} rules={[{required:true}]}>
                    <Select disabled={true}>
                        <Select.Option value={"dense"}>Dense</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={"Line Item Count"} name={"line_item_count"} rules={[{required:true}]}>
                    <InputNumber  min={1}/>
                </Form.Item>
                <Form.Item label={"Order Count"} name={"order_count"} rules={[{required:true}]}>
                    <InputNumber min={1} disabled={true}/>
                </Form.Item>
                <Form.Item label={"Prefix"} name={"prefix"} rules={[{required:true}]}>
                    <Input maxLength={4}/>
                </Form.Item>
                <Form.Item {...formSubmitButtonCommonProps}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    </AppBase>
}
