import React, {useEffect, useState} from "react";
import {getAPI} from "../../../../../../utils/apiRequest";
import {GAM_AUTOMATION_LIST, GAM_AUTOMATION_LOG_LIST} from "../../../../../../constants/api";
import * as _ from "lodash";
import {Timeline, Typography} from "antd";
import {interpolate} from "../../../../../../utils/common";
import {CheckCircleFilled, LoadingOutlined, WarningFilled} from "@ant-design/icons";
const {Text} = Typography
export default function ExpandedRowLogTimeline({automationId}: { automationId: any }) {
    const [automationsList, setAutomationsList] = useState([])
    const loadAutomations = () => {
        // @ts-ignore
        getAPI(interpolate(GAM_AUTOMATION_LOG_LIST, [automationId]), {
            pagination: false
        }).then(result => {
            setAutomationsList(result)
        })
    }
    useEffect(()=>{
        loadAutomations()
        const intervalId = setInterval(() => {
            loadAutomations()
        }, 10000)
        return () => clearInterval(intervalId);
    }, [])
    return <Timeline>
        {automationsList.map((item: any) =>
            <Timeline.Item key={item.id} color={timelineColorFetcher(item.status)} dot={timelineIconFetcher(item.status)}>
                {item.description}
                {item.error_description ? <Text type={timelineTypeFetcher(item.status)}><br/>{item.error_description}</Text> : null}
            </Timeline.Item>)}
    </Timeline>
}

function timelineColorFetcher(status:string){
     switch(status) {
         case 'FINISHED':
             return 'green'
         case 'IN_PROGRESS':
             return 'blue'
         case 'PENDING':
             return 'grey'
         case 'FAILED':
             return 'red'
         default:
             return 'blue'
     }
}

function timelineIconFetcher(status:string){
    switch(status) {
        case 'FINISHED':
            return <CheckCircleFilled/>
        case 'IN_PROGRESS':
            return <LoadingOutlined/>
        case 'PENDING':
            return null
        case 'FAILED':
            return <WarningFilled/>
        default:
            return null
    }
}

function timelineTypeFetcher(status:string){
    switch(status) {
        case 'FINISHED':
            return 'success'
        case 'IN_PROGRESS':
            return 'secondary'
        case 'PENDING':
            return undefined
        case 'FAILED':
            return 'danger'
        default:
            return undefined
    }
}