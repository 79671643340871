import React from 'react';
import {Avatar, Button, Col, Dropdown, Layout, Menu, Row, Space, theme, Typography} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';
import * as _ from "lodash"
import {logoutReduxAction} from "../../../redux/actions/authActions";
import {useAppDispatch} from "../../../lib/hooks";
import {BulbFilled} from "@ant-design/icons"
import {globalToggleTheme} from "../../../redux/actions/globalReduxActions";
import {useSelector} from "react-redux";

const {Header} = Layout;
const {Title} = Typography;
const { useToken } = theme;
export default function AppHeader(props: any) {
    const { token } = useToken();
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const darkTheme  = useSelector(state => _.get(state, 'global.darkTheme', false))
    const logOutUser = () => {
        // @ts-ignore
        dispatch(logoutReduxAction())
    };
    const onHandleLink = (event: any) => {
        if (event.key === 'logout') {
            logOutUser();
        } else {
            navigate(event.key);
        }
    };
    const toggleTheme = () => {
        // @ts-ignore
        dispatch(globalToggleTheme(!darkTheme))

    }
    const {loginUser} = props;
    let userMenu = (
        <Menu onClick={onHandleLink}>
            {/*<Menu.Item key={'/user/profile'}>*/}
            {/*    My Profile*/}
            {/*</Menu.Item>*/}
            <Menu.Item key={'/user/change-password'}>
                Change Password
            </Menu.Item>
            <Menu.Item key={'logout'}>
                Log Out
            </Menu.Item>
        </Menu>
    );
    return (
        <Header
            className='site-layout-background'
            style={{
                padding: '0 20px',
                background: 'transparent',
                zIndex: 1,
                width: '100%',
            }}
        >
            <Row justify={'space-between'}>
                <Col>

                </Col>
                <Col>
                    <div style={{padding: '0 10px', cursor: 'pointer'}}>

                            <Space align='baseline'>
                                <Button type={"text"} icon={<BulbFilled/>} onClick={toggleTheme} shape={"round"}/>
                                <span>{`Hi, ${_.get(loginUser, 'first_name', "")}`}</span>
                                <Dropdown overlay={userMenu} className={"dark"}>
                                <Avatar src={_.get(loginUser, 'image')} style={{backgroundColor: '#87d068'}}
                                        icon={<UserOutlined/>}>{_.get(loginUser, 'first_name', "")[0]}</Avatar>
                                </Dropdown>
                            </Space>
                    </div>
                </Col>
            </Row>
        </Header>
    );

}

