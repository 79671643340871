import {Col, DatePicker, Divider, Row, Space, Spin, Statistic, Table, Typography} from "antd";
import {useEffect, useState} from "react";
import {getAPI} from "../../../../../../utils/apiRequest";
import {GAM_DAY_WISE_REPORT} from "../../../../../../constants/api";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
const {RangePicker} = DatePicker
const {Title,Text} = Typography;
export default function DayWiseRevenueReport() {
    const [tableData, setTableData] = useState<any>({results: [], extra: []});
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({page_size: 10, page: 1});
    const [filters,setFilters] = useState({date__gte: dayjs().subtract(7,'days').format('YYYY-MM-DD'), date__lte: dayjs().format('YYYY-MM-DD') })
    const params = useParams();
    const getReport = () => {
        setLoading(true)
        getAPI(GAM_DAY_WISE_REPORT, {...pagination, site_id: params.siteId, ...filters}).then(res => {
            setTableData(res)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }
    useEffect(() => {
        if (params.siteId)
            getReport()
    }, [params.siteId, pagination, filters])
    const columns = [{
        title: 'Date',
        dataIndex: 'date',
        key: 'date'
    }, {
        title: 'Total eCPM',
        dataIndex: 'total_ecpm',
        key: 'total_ecpm',
        render: (value: number) => `$ ${(value).toFixed(2)}`
    }, {
        title: 'Total Imp.',
        dataIndex: 'total_ad_ex_imp',
        key: 'total_ad_ex_imp',
    }, {
        title: 'Revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        align:undefined,
        render: (value: number) => `$ ${(value).toFixed(2)}`
    }]

    return <Spin spinning={loading} tip={"Loading Data..."}>
        <Row gutter={16}>
            <Col span={24} style={{padding:'0px 16px'}}>
                <Row gutter={[8,16]} justify={"end"} align={"middle"}>
                    <Col md={4}>
                        <Typography  style={{textAlign:'right'}}>Date</Typography>
                    </Col>
                    <Col>
                        <RangePicker allowClear={false} format={'YYYY-MM-DD'} value={[dayjs(filters.date__gte),dayjs(filters.date__lte)]} onChange={(values)=>setFilters((prevState:any)=>{
                            return {
                                ...prevState,
                                date__gte: dayjs(values?values[0]:null).format('YYYY-MM-DD'),
                                date__lte: dayjs(values?values[1]:null).format('YYYY-MM-DD')
                            }
                        })}/>
                    </Col>
                </Row>
            </Col>
            <Divider style={{padding:0}}/>
            <Col span={24}>
                {/*{tableData.extra?.map((row: any) => <Row justify={"space-around"}>*/}
                {/*    <Col md={4} xs={6}>*/}
                {/*        <Statistic title="Total Impressions" value={row.all_imp}/>*/}
                {/*    </Col>*/}
                {/*    <Col md={4} xs={6}>*/}
                {/*        <Statistic title="eCPM" value={row.ecpm} precision={2} prefix={'$'}/>*/}
                {/*    </Col>*/}
                {/*    <Col md={4} xs={6}>*/}
                {/*        <Statistic title="Active View" value={row.active_view} precision={2} suffix={"%"}/>*/}
                {/*    </Col>*/}
                {/*</Row>)}*/}
            </Col>
            <Col span={24}>
                <Table columns={columns}
                       size={"small"}
                       dataSource={tableData.results}
                       pagination={{
                           disabled: loading,
                           total: tableData.count,
                           pageSize: pagination.page_size,
                           current: pagination.page,
                           pageSizeOptions: [10, 20, 50, 100, 500],
                           showSizeChanger: true,
                           position: ["topRight", "bottomRight"],
                           showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total}`,
                           onChange: (page, pageSize) => setPagination({page, page_size: pageSize})
                       }}/>
            </Col>
        </Row>

    </Spin>
}