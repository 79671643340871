import React from 'react';
import {Layout, Menu} from 'antd';
import {Link} from 'react-router-dom';
import {FileImageOutlined, HomeOutlined, SendOutlined,} from '@ant-design/icons';
import * as _ from "lodash"

const {Sider} = Layout;
const {SubMenu} = Menu;

export default function UserBaseSider() {
    return (
        <Sider
            trigger={null}
            collapsible
            width={225}
            theme={'light'}
        >
            <RoleMenuSelector/>
        </Sider>
    );

}


function RoleMenuSelector(props: any) {
    return (
        <Menu
            mode='inline'
            // defaultSelectedKeys={[props.location.pathname]}
            key={`${_.get(props.route, 'cat')}-${_.get(props.route, 'subCat')}`}
            defaultOpenKeys={[
                `${_.get(props.route, 'cat')}-${_.get(props.route, 'subCat')}`,
            ]}
            style={{
                overflowX: 'hidden',
                overflowY: 'scroll',
            }}
        >
            {/*<Menu.Divider/>*/}
            {/*<Menu.Item key={'/user/profile'} icon={<HomeOutlined/>}>*/}
            {/*    <Link to={'/user/profile'}>Profile</Link>*/}
            {/*</Menu.Item>*/}
                <Menu.Item key={'/user/change-password'} icon={<SendOutlined/>}>
                    <Link to={'/user/change-password'}>Change Password</Link>
                </Menu.Item>
        </Menu>
    );

    return null;
}
