import {GLOBAL_SIDER_COLLAPSED, GLOBAL_SIDER_MENU, GLOBAL_TOGGLE_THEME, GLOBAL_UPDATE_ROUTE,} from '../reduxConstant';
import get from 'lodash/get';

export default (state = {}, action:{type:string,payload:any}) => {
  switch (action.type) {
    case GLOBAL_UPDATE_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case GLOBAL_SIDER_COLLAPSED:
      return {
        ...state,
        siderCollapsed: action.payload,
      };
    case GLOBAL_SIDER_MENU:
      return {
        ...state,
        currentSiderMenu: get(action, 'payload'),
      };
    case GLOBAL_TOGGLE_THEME:
      return {
        ...state,
        darkTheme: get(action, 'payload'),
      };
    default:
      return {
        ...state,
      };
  }
};
