import {LOGIN, LOGOUT} from "../reduxConstant";


export const loginReduxAction = (response) => (dispatch) => {
    return dispatch({
        type: LOGIN,
        payload: response,
    });
};

export const logoutReduxAction = () => (dispatch) => {
    return dispatch({
        type: LOGOUT,
        payload: undefined,
    });
};