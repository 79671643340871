import AppBase from "../../../../../base/AppBase";
import {Button, Col, Collapse, Pagination, Row, Space, Table, Tag, Typography} from "antd";
import * as _ from "lodash";
import React, {useEffect, useState} from "react";
import {getAPI} from "../../../../../../utils/apiRequest";
import {GAM_AUTOMATION_LIST, GAM_ORDER_LIST} from "../../../../../../constants/api";
import {SendOutlined} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import ExpandedRowLogTimeline from "./ExpandedRowLogTimeline";
import {useAppSelector} from "../../../../../../lib/hooks";

const {Title} = Typography;
export default function CreateAutomation() {
    const params = useParams()
    const advertisers: any = useAppSelector(state => _.get(state, 'site.selected.advertisers', {}))
    const traffickers: any = useAppSelector(state => _.get(state, 'site.selected.traffickers', {}))
    const [loading, setLoading] = useState(false)
    const [automationsList, setAutomationsList] = useState({current:0,next:0,results: []})
    const [paginationData, setPaginationData] = useState({
        page_size: 5,
        page: 1
    })
    const [activeAccordion, setActiveAccordion] = useState<string | string[]>([])
    const loadAutomations = () => {
        if(loading && paginationData.page)
            return
        setLoading(true)
        getAPI(GAM_AUTOMATION_LIST, {
            ...paginationData,
            site: params.siteId
        }).then(result => {
            setAutomationsList((prevState) => {
                if(prevState.current !== result.current)
                    return { ...result, results: [...prevState.results,...result.results]}
                return prevState
            })
            if(result.current==1 && result.count){
                setActiveAccordion([result.results[0]?.id.toString()])
            }
            setLoading(false)
        })
    }
    useEffect(loadAutomations, [paginationData])
    return <AppBase>
        <Title level={2}>
            Quick Automation
            <Link to={`/${params.siteId}/gam/automate/create`}>
                <Button type={"primary"} className={"pull-right"} icon={<SendOutlined/>}>Start GAM Automation</Button>
            </Link>
        </Title>
        <Collapse destroyInactivePanel={true} activeKey={activeAccordion || []} accordion={true}
                  className={"automation-collapse"} onChange={(key) => setActiveAccordion(key)} ghost>
            {_.get(automationsList, 'results')?.map((item: any) =>
                <Collapse.Panel key={item.id}
                                extra={<Tag color={tagColorFetcher(item.status)}>{item.status}</Tag>}
                                header={<Space>
                                    <Tag>Advertiser: {advertisers[item.advertiser_id]?.name} ({item.advertiser_id})</Tag>
                                    <Tag>Trafficker: {traffickers[item.trafficker_id]?.name} ({item.trafficker_id})</Tag>
                                    <Tag>Line Item Count: {item.line_item_count}</Tag>
                                    <Tag>Order Count: {item.order_count}</Tag>
                                    {/*<Tag>Creative Size: {item.creative_size}</Tag>*/}
                                </Space>}>
                    <ExpandedRowLogTimeline automationId={item.id} key={item.id}/>
                </Collapse.Panel>)}
        </Collapse>
        <Row justify={"space-around"}>
            <Col>
                <Button loading={loading} type={"primary"} className={"text-center"} disabled={!automationsList.next} onClick={() => setPaginationData(prevState => {
                    return {...prevState, page: automationsList.next}
                })}>
                    Load More
                </Button>
            </Col>
        </Row>
    </AppBase>
}

function tagColorFetcher(status: string) {
    switch (status) {
        case 'FINISHED':
            return 'success'
        case 'IN_PROGRESS':
            return 'processing'
        case 'PENDING':
            return 'default'
        case 'FAILED':
            return 'error'
        default:
            return 'default'
    }
}
