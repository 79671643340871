import React, {ReactNode} from 'react';
import {Card, Col, Layout, Row, Typography} from 'antd';
import AppBase from "../base/AppBase";
import UserBaseSider from "./UserBaseSider";

const {Text} = Typography;
const {Content} = Layout;

export default function UserBase({
                                     noSider,
                                     noHeader,
                                     loggedIn,
                                     noPadding,
                                     noBreadcrumb,
                                     children,
                                     ...props
                                 }: {
    noSider?: boolean | undefined,
    noHeader?: boolean | undefined,
    loggedIn?: boolean | undefined,
    noPadding?: boolean | undefined,
    noBreadcrumb?: boolean | undefined,
    children: ReactNode
}) {
    return (
        <AppBase noSider={true} noHeader={noHeader} noPadding={noPadding} noBreadcrumb={noBreadcrumb}>
            <Card bodyStyle={{padding: 0}}>
                <Layout style={{background: "white"}}>
                    {noSider ? null : <UserBaseSider/>}
                    <Content style={{padding:24}}>
                        {children}
                    </Content>
                </Layout>
            </Card>
        </AppBase>
    );
}

