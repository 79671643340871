import AppBase from "../../../../../base/AppBase";
import {Table, Typography} from "antd";
import {getAPI} from "../../../../../../utils/apiRequest";
import {GAM_ORDER_LIST, SITE_USERS} from "../../../../../../constants/api";
import {useEffect, useState} from "react";
import * as _ from "lodash";
import {useParams} from "react-router-dom";

const {Title} = Typography;
export default function SiteSettignUsers() {
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [usersList, setUsersList] = useState({})
    const [paginationData, setPaginationData] = useState({
        pageSize: 20,
        current: 1
    })
    const loadUsers = () => {
        setLoading(true)
        getAPI(SITE_USERS, {
            page_size: paginationData.pageSize,
            page: paginationData.current,
            site: params.siteId
        }).then(result => {
            setUsersList(result)
            setLoading(false)
        })
    }
    useEffect(loadUsers, [paginationData])
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    }, {
        title: 'Email',
        dataIndex: "user_data",
        key: 'email',
        render: (value:any )=> _.get(value,'email')
    }, {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
    }]
    return <>
        <Table size="small"
               loading={loading}
               dataSource={_.get(usersList, 'results')}
               columns={columns}
               pagination={{
                   total: _.get(usersList, 'totalResultSetSize'),
                   ...paginationData
               }}
               onChange={(pagination, filters, sorter, extra)=>setPaginationData(prevState => { return {...prevState, ...pagination}})}/>
    </>
}